.app_button {
  @apply bg-blue px-8 py-3 rounded-lg flex items-center justify-center text-white w-max transition-all duration-200;
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &.no-bg {
    @apply bg-white border-blue border-[1px] text-black-400;
  }
  &.full {
    @apply w-full;
  }
  svg {
    @apply mr-3;
  }
  &.grey {
    background-color: #97a2b6;
  }
  &.trans {
    @apply text-black-400;
    background-color: #fff;
  }
  &.white {
    @apply text-black-300;
    border: 1px solid rgba(18, 18, 18, 0.1);
    background-color: rgba(18, 18, 18, 0.02);
  }
}
