.api-services {
  @apply flex flex-col;
  scroll-behavior: smooth;
  width: 100%;
  transition: all 1 ease-in-out;

  &__available {
    @apply w-full mt-5 flex flex-col items-start gap-3;
    
    .title {
      font-size: 18px;
      font-weight: 600;
    }

    .networks {
      @apply w-full h-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 2xl:gap-7;

      .box {
        @apply flex items-center bg-white gap-2 p-2;
        font-size: 15px;
        font-weight: 600;
        border: 1px solid #FFFFFF;
        border-radius: 15px;
        box-shadow: 0px 4px 20px rgba(91, 71, 188, 0.05);
        &:hover {
          border: 1px solid #045CF4;
        }
        @media screen and (min-width: 765px) {
          font-size: 12px;
        }
        @media screen and (min-width: 950px) {
          font-size: 15px;
        }
      }
    }
  }

  &__connected {
    @apply w-full mt-5 flex flex-col items-start gap-3;
    
    .title {
      font-size: 18px;
      font-weight: 600;
    }

    .box {
      @apply w-full min-h-[60px] flex items-center justify-between bg-white py-1 px-5;
      font-size: 15px;
      font-weight: 600;
      border: 1px solid #FFFFFF;
      border-radius: 15px;
      box-shadow: 0px 4px 20px rgba(91, 71, 188, 0.05);
    }

    .avatars {}

    .options {
      @apply w-5 flex justify-center;
    }
  }

  &__active {
    @apply w-full mt-10 flex flex-col items-start gap-3;

    .title {
      font-size: 18px;
      font-weight: 600;
    }

    .container {
      @apply w-full flex flex-col gap-3 p-0;

      .box {
        @apply flex items-center w-full bg-white p-1 px-7;
        box-shadow: 0px 4px 20px rgba(91, 71, 188, 0.05);
        border-radius: 16px;
        font-size: 15px;
        font-weight: 600;

        &__header {
          @apply relative flex items-center w-full px-7;
          font-size: 14px;
          color: #12121299;
        }
      }
    }
  }
}